// Variables
// --------------------------  
 
// Colors and backgrounds
// --------------------------

$darker:                      #000000!default;
$lighter: 		              #ffffff!default;
$text-color:                  #666769;  
$link-color:                  #BE2938;
$title-color:                 #515257;
$main-color:                  #000000; 

$color1:                      #212940;
$color2:                      #B02130;
$color3:                      #373E53;
$color4:                      #949DB8;
$color5:                      #F5F5F5;
$color6:                      #CACED9;
$color7:                      #1A2135;
$color8:                      #E3E4E7;
$color9:                      #C6CCDE;
$color10:                     #818BA8;
$color11:                     #2E364E;
$color12:                     #6c7288;
$color13:                     #EBDBDC;
$color14:                     #d4d2d3;
$color15:                     #ADAFB3;
$color16:                     #dadada;




//== Typography
// -------------------------- 
$main-font: 	'Lato', sans-serif;
$sub-font: 	    'Playfair Display', serif;
$sub-font1: 	'Lato-Medium';
$sub-font2: 	'Roboto', sans-serif;

$thin:       100;
$light:      300;
$regular:    400;
$medium:     500;
$semibold:   600;
$bold:       700;
$extrabold:  800;
$black:      900;


$size-base:    16px !default; 

$size-h1:                       ceil(($size-base * 2.38)) 		    !default;   // ~38px
$size-h2:                       ceil(($size-base * 2.25)) 		    !default;   // ~36px
$size-h3:                       ceil(($size-base * 1.87)) 			!default;   // ~30px
$size-h4:                       ceil(($size-base * 1.50)) 			!default;   // ~24px
$size-h5:                       ceil(($size-base * 1.375)) 			!default;   // ~22px
$size-h6:              		    ceil(($size-base * 1.25)) 			!default;   // ~20px

$text-size:              		ceil(($size-base * 1.06)) 			!default;   // ~17px
$size-md:              		    ceil(($size-base * 1.125)) 			!default;   // ~18px
$size-xmd:              		ceil(($size-base * 0.937)) 			!default;   // ~15px
$sm-size:              		    ceil(($size-base * 0.88)) 			!default;   // ~14px


//== Media queries breakpoints
$desktop-only:      "(min-width: 768px)";/*@media #{$desktop-only} {}*/
$tablet-landscape:  "(min-width: 1024px) and (max-width: 1279px)";  
$tablet-portrait:   "(min-width: 768px) and (max-width: 1023px)";
$mobile-landscape:  "only screen and (max-width : 767px)";
$mobile-portrait:   "only screen and (max-width : 479px)";  

// My mixins
@mixin font-size($size) {
    font-size: $size + px;
    font-size: $size / 16 + em;
    font-size: $size / 16 + rem;
}

@mixin mediaQuery($args...) {
   @if length($args) == 1 {
       @media screen and (max-width: nth($args,1) / 16 + em) {
           @content;
       }
   } @else if nth($args,2) == up {
       @media screen and (min-width: nth($args,1) / 16 + em) {
           @content;
       }
   } @else {
       @media screen and (min-width: nth($args,1) / 16 + em) and (max-width: nth($args,2) / 16 + em) {
           @content;
       }
   }   
}


.input-outer {
    position: relative;
    input[type="text"] {
        padding-left: 10px;
    }
    span {
        position: absolute;
        top: 18px;
        right: 16px;
        color: $color2;
        @include font-size(23);
        font-weight: bold;
    }
}

.text-block {
    &.input-outer {
        input {
            padding-left: 0;
        }
    }
}

.main-textarea {
    padding: 24px 10px;
    height: 238px;
}
 

.checkbox-outer {
    position: relative;
    input {
        display: none;
    }
    label {
        display: inline-block;
        margin: 0 0 8px;
        span {
            width: 24px;
            height: 24px;
            display: inline-block;
            border: 2px solid $color14;
            vertical-align: middle;
            margin: 0 .5em 0 0;
        }
        &.is-invalid-label {
            color: #000;
            span {
                border-color: #cc4b37;
            }           
        }
    }   
    input:checked + label {
        span {
            background: transparent url('../images/tick.svg') no-repeat center center;
        }
    }
}

form {
    .check-box {
        input[type="checkbox"] + label {
            &.is-invalid-label {
                &::before {
                    border-color: #cc4b37;
                }                
            }
        }
    }        
}


.column, .columns {
    @include mediaQuery(599) {
        flex: 0 0 100%;
    }    
}
