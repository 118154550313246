.servicios-legales{
    .property-block{
        margin: 0;
        padding:55px 0 170px;
        @media #{$tablet-landscape} { 
            padding:55px 0 120px;
        }
        @media #{$tablet-portrait} { 
            padding:55px 0 90px;
        }
        @media #{$mobile-landscape} { 
            padding:20px 0 40px;
        }
    }
    .single-block{
        a{
            background: none;
            border: solid 1px $color16;
        }
        h3{
           color: #373E53; 
        }
        p{
            color: #373E53;
        }
        .image-div{
                i{
                &.icon1{
                    background: url('../images/icon1s.svg') no-repeat;
                } 
                &.icon2{
                    background: url('../images/icon2s.svg') no-repeat;
                } 
                &.icon3{
                    background: url('../images/icon3s.svg') no-repeat;
                } 
                &.icon4{
                    background: url('../images/icon4s.svg') no-repeat;
                } 
                &.icon5{
                    background: url('../images/icon5s.svg') no-repeat;
                } 
                &.icon6{
                    background: url('../images/icon6s.svg') no-repeat;
                } 
            }
        }
        &:hover,
        &:active,
        &:focus{
            background: none;
            a{
                border-color: $color2;
            }
            p{
                color: #373E53;
            }
            h3{
                color: #373E53;
            }
            .image-div{
                i{
                    &.icon1{
                        background: url('../images/icon1s.svg') no-repeat;
                    } 
                    &.icon2{
                        background: url('../images/icon2s.svg') no-repeat;
                    } 
                    &.icon3{
                        background: url('../images/icon3s.svg') no-repeat;
                    } 
                    &.icon4{
                        background: url('../images/icon4s.svg') no-repeat;
                    } 
                    &.icon5{
                        background: url('../images/icon5s.svg') no-repeat;
                    } 
                    &.icon6{
                        background: url('../images/icon6s.svg') no-repeat;
                    } 
                }
            }
        }
    } 
}

.top-content-container{
    padding: 80px 0 0;
    @media #{$tablet-landscape} { 
       padding: 60px 0 0; 
    }
    @media #{$tablet-portrait} { 
        padding: 50px 0 0;
    }
    @media #{$mobile-landscape} { 
        padding: 50px 0 0;
    }
}