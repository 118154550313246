/**********************************************
    Fonts
***********************************************/

@font-face {
    font-family: 'Lato-Medium';
    src: url('../fonts/Lato-Medium.eot');
    src: url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Medium.woff2') format('woff2'),
        url('../fonts/Lato-Medium.woff') format('woff'),
        url('../fonts/Lato-Medium.svg#Lato-Medium') format('svg');
    font-weight: bold;
    font-style: normal;
}