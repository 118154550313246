/**********************************************
    Home Page 
***********************************************/
.block{
	width: 100%;
	background: #030;
	display: block;
	height: 40px;
    text-align: center;
    margin: 0 0 5px;
	&.aa{
		background: red;
	}
	&.bb{
		background: green;
	}
}

.latest-news-container{
    background: $color3; 
    padding: 52px 0 66px;
    @media #{$mobile-landscape} { 
        padding: 50px 0 10px;
    }
    .title h3{
        color: $lighter;
    }
}
.title{
    text-align: center;
    position: relative;
    margin: 0 0 48px;
    @media #{$mobile-landscape} { 
       margin: 0 0 30px; 
    }
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 53px;
        height: 2px;
        background: $color12;
    }
    h3{
       padding: 0 0 25px; 
    }
}
.news-block{
    @media #{$mobile-landscape} { 
        padding: 0 0 40px;
    }
    h6{
        color: $lighter;
        padding:0 0 19px;
        @media #{$tablet-portrait} { 
            font-size: 16px;
            padding:0 0 15px;
        }
    }
    p{
        font-size: $size-base;
        line-height: 1.75;
        color: $color9;
        padding:0 0 25px;
        @media #{$tablet-portrait} { 
            font-size: 14px;
        }
    }
}
.more{
    display: inline-block;
    font-family: $sub-font;
    font-weight: $regular;
    font-size: $size-base;
    line-height: 2.25;
    color: $lighter;
    background: $color1;
    border-radius: 20px;
    padding: 0 25px;
    height: 38px;
    &:hover, &:focus{
       background: $color2;
       color: $lighter; 
    }
}

.contact-block-container{
    padding:60px 0 78px;
    @media #{$tablet-portrait} { 
       padding:50px 0; 
    }
    @media #{$mobile-landscape} { 
       padding:50px 0;  
    }
    form{
        .columns {
            padding-right: 13px;
            padding-left: 13px;
        }
    }
    .title{
        margin: 0 0 33px;
    }
}
.check-block{
    text-align: right;
    padding:4px 0 0;
    .check-box{
        display: inline-block;
        vertical-align: top;
    }
}

.intro-content-container{
    padding:80px 0 125px;
    @media #{$tablet-landscape} { 
        padding:60px 0 80px;
    }
    @media #{$tablet-portrait} { 
        padding:50px 0 70px;
    }
    @media #{$mobile-landscape} { 
        padding:20px 0 50px;
    }
}
.intro-content-outer{
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @media #{$mobile-landscape} { 
        @include flex-wrap(wrap);
    }
}
.text-block{
    h1{
        color: $link-color;
        padding:0 0 23px;
        margin: 0 0 24px;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 53px;
            height: 2px;
            background: $color15;
        }
    }
}
.intro-content{
    width: 47%;
    @media #{$tablet-portrait} { 
       width: 55%; 
    }
    @media #{$mobile-landscape} { 
        width: 100%;
        @include order(2);
    }
    
    p{
        padding:0 0 40px;
    }
    .more{
        font-size: $size-h6;
        line-height: 2.40;
        height: 50px;
        border-radius: 30px;
        min-width: 170px;
        text-align: center;
    }
}
.intro-image{
    width: 38%;
    @media #{$tablet-portrait} { 
        width: 40%;
    }
    @media #{$mobile-landscape} { 
       width: 100%;
        @include order(1);
        padding: 0 0 30px;
    }
    img{
        display: block;
        width: 100%;
    }
}

.property-block {
    margin: -103px 0 0;
    z-index: 1;
    position: relative;
    
    @media #{$mobile-landscape} {
      margin: -30px 0 0;
    }
}

.single-block{
    
    text-align: center;
    min-height: 283px;
    margin-bottom: 12px;
    display: inline-flex;
    @media #{$mobile-landscape} {
       min-height: auto;
    }
    a{
        display: inline-block;
        padding: 34px 35px;
        background: $color2;
        
        @media #{$tablet-landscape} {
            padding: 34px 14px;
        }
        @media #{$tablet-portrait} {
           padding: 24px 15px;
        }
        @media #{$mobile-landscape} {
           padding: 25px 20px;
        }
        &:hover,
        &:active,
        &:focus{
            background: $color1;
            p{
                color: $color9;
            }
            .image-div{
                i{
                    &.icon1{
                        background: url('../images/icon1-hover.svg') no-repeat;
                    } 
                    &.icon2{
                        background: url('../images/icon2-hover.svg') no-repeat;
                    } 
                    &.icon3{
                        background: url('../images/icon3-hover.svg') no-repeat;
                    } 
                    &.icon4{
                        background: url('../images/icon4-hover.svg') no-repeat;
                    } 
                    &.icon5{
                        background: url('../images/icon5-hover.svg') no-repeat;
                    } 
                    &.icon6{
                        background: url('../images/icon6-hover.svg') no-repeat;
                    } 
                }
            }
        }
    }
    h3{
        color: $lighter;
        padding-bottom: 18px;
        
        @media #{$tablet-landscape} {
            font-size: 28px;
        }        
        @media #{$tablet-portrait} {
           font-size: 21px;
        }
    }
    p{
        color: $color13;
        font-size: $size-xmd;
        padding-bottom: 0;
        line-height: 28px;
        
        @media #{$mobile-landscape} {
           line-height: 25px;
        }
    }
    .image-div{
        height: 58px;
        margin-bottom: 15px;
        i{
            &.icon1{
                background: url('../images/icon1.svg') no-repeat;
                width:53px;
                height: 52px;
                display: inline-block;
            }
            &.icon2{
                background: url('../images/icon2.svg') no-repeat;
                width:59px;
                height: 54px;
                display: inline-block;
            }
            &.icon3{
                background: url('../images/icon3.svg') no-repeat;
                width:53px;
                height: 52px;
                display: inline-block;
            }
            &.icon4{
                background: url('../images/icon4.svg') no-repeat;
                width:64px;
                height: 56px;
                display: inline-block;
            }
            &.icon5{
                background: url('../images/icon5.svg') no-repeat;
                width:42px;
                height: 53px;
                display: inline-block;
            }
            &.icon6{
                background: url('../images/icon6.svg') no-repeat;
                width:48px;
                height: 51px;
                display: inline-block;
            }
        }
    }
}


