/**********************************************
    Banner
***********************************************/
.banner-container{
    width: 100%;
    position: relative;
    .banner-image{
        height: 440px;
        position: relative;
        @media #{$mobile-landscape} {
            height: 310px;
        }
        @media #{$mobile-portrait} {
            height: 395px;
        }
        
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(23,23,23,0.4);
                
        }
    }
    .banner-content{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 69px;
        width: 100%; 
        @media #{$mobile-landscape} {
          @include translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }   
        .banner-content-inner{
            max-width: 625px;
            @media #{$mobile-landscape} {
                margin: 0 auto;
            }
            h2{
                padding-bottom: 25px;
                color: $lighter; 
                text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
            }
            p{
                color: $lighter;
                font-size: $size-md;
                line-height: 26px;
                padding-bottom: 17px;
                @media #{$mobile-landscape} {
                    font-size: 16px;
                }
                strong{
                    font-weight: $bold;
                    color: $lighter !important;
                }
            }
        }
    }
    
}


