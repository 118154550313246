.main-content {
    padding-bottom: 3rem;
    h3 {
        margin-bottom: 1rem;
        font-size: 24px;
    }
}

#contacto {
    .address-block {
        .inner-address-block {
           
            .contact-block {
                h2 {
                    @include font-size(24);
                    margin: 0 0 .25em;
                }
            }
            .offices-block {
                display: flex;
                flex-wrap: wrap;
                .office-thumb {
                    width: calc(50% - 1.5em);
                    margin: 0 1.5em 0 0;
                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                    h6 {
                        @include font-size(18);
                        border-bottom: 2px solid $color14;
                        margin: 0 0 5px;
                    }
                    p {
                        @include font-size(16);
                        line-height: 1.6;
                        padding: 0 0 16px;
                    }
                }
            }
            @include mediaQuery(600,1023) {
                display: flex;
                .contact-block {
                    width: 40%;
                }
            }
            @include mediaQuery(599) {
                .offices-block {
                    .office-thumb {
                        width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }      
        
    }
    form {
        .more {
            @include font-size(20);
            line-height: 2.40;
            height: 50px;
            border-radius: 30px;
            min-width: 170px;
            text-align: center;
            float: right;
            border: none;
            outline: none;
            box-shadow: none;
            cursor: pointer;
        }
    }
    #contact-map {
        width: 100%;
        height: 460px;
    }
}

.article-page {
    .main-content {
        h2, h3, h4, h5, h6 {
            line-height: 1.2;
            margin: 0 0 .5em;
            font-weight: bold;
        }
        h2 {
            @include font-size(30);
        }
        h3 {
            @include font-size(28);
        }
        h4 {
            @include font-size(25);
        }
        h5 {
            @include font-size(21);
        }
        h6 {
            @include font-size(17);
        }
        p {
            @include font-size(17);
            line-height: 1.88;
            margin: 0 0 2em;
            padding: 0;
            strong, b {
                font-weight: bold;
            }
            i,em {
                font-style: italic;
            }
            a {
                text-decoration: underline;
                font-size: inherit;
                &:hover {
                    color: $color2;
                }
            }
        }
        ul, ol {
            margin: 0 0 2em;
            list-style-position: inside;
            li {
                margin: .25em 0;
            }
        }
        ul {
            margin-left: 1em;
            list-style: none;
            li {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    border-radius: 50%;
                    left: -16px;
                    top: 12px;
                    width: 8px;
                    height: 8px;
                    border: 2px solid $color11;
                }
            }
        }
    }
}

.webadmin-login {
    #login-form {
        margin: 0 0 3.5em;
        .more {
            width: 100%;
            outline: none;
            border: none;
            box-shadow: none;
            cursor: pointer;
            height: 50px;
            @include font-size(20);
        }
    }
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .site-by {
        color: $color10;
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
    @include mediaQuery(599) {
        flex-wrap: wrap;
        justify-content: center;
        .site-by {
            margin: .5em 0 0;
        }
    }
}

// Homepage red blocks fix
.grid-list {
    max-width: 72rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: 1fr;
    gap: 12px;
    .single-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 34px 35px;
        background: $color2;
        &:hover {
            background-color: $color1;
        }
    }
    @include mediaQuery(600,1023) {
        grid-template-columns: repeat(2,1fr);
    }
    @include mediaQuery(599) {
        grid-template-columns: 1fr;
    }
}

.property-block.services {
    .grid-list {
        .single-block {
            border: 1px solid $color16;
            background-color: transparent;
            &:hover {
                border-color: $color2;
            }
        }
    }
}

