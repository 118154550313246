/**********************************************
    Footer
***********************************************/

.footer-container{
    width: 100%;
    background: $color7;
    padding: 63px 0 0px;
    
    @media #{$tablet-portrait} {
        padding: 53px 0 0px;
    }
    @media #{$mobile-landscape} {
       text-align: center;
        padding: 30px 0 0px;
    }
}
.footer-top{
  
    padding: 0px 0 47px;
    @media #{$tablet-landscape} {
        padding: 0px 0 27px;
    }
    @media #{$tablet-portrait} {
        padding: 0px 0 27px;
    }
    @media #{$mobile-landscape} {    
        padding: 0px 0 7px;
    }
}
.footer-single{
    width: 100%;
   
    h6{
        color: $color8;
        font-family: $sub-font;
        font-weight: $bold;
        padding-bottom: 20px;
        @media #{$mobile-landscape} {
            padding-bottom: 10px;
        }
    }
    p{
        color: $color9;
        line-height: 28px;
        font-size: $size-base;
        
        @media #{$tablet-portrait} {
             line-height: 25px;
            font-size: 14px;
            padding-bottom: 20px;
        }
    }
    ul{
        li{
           @media #{$mobile-landscape} {
                line-height: 25px;
            }
            a{
                color: $color9;
                font-size: $size-base;
                line-height: 20px;
                &:hover,
                &:focus,
                &:active{
                    color: $color2;
                }
                @media #{$tablet-portrait} {
                    font-size: 14px;
                }
            }
        }
    }
    &.about-text{
       width: 100%;
        padding-right: 125px;   
        @media #{$tablet-landscape} {
            padding-right: 55px;   
        } 
        @media #{$tablet-portrait} {
            padding-right: 35px;   
        }
        @media #{$mobile-landscape} {
           padding-right: 0;         
        }
    }
    &.contact-div{
       width: 100%;        
        @media #{$mobile-landscape} {
           width: 100%;
           padding-top: 25px;
        }
        p{
            line-height: 25px;
            
            @media #{$tablet-portrait} {
               padding: 0 0 20px;
            }
            @media #{$mobile-landscape} {
               padding: 0 0 20px;
            }
        }
    }
    .single-row{
        width: 100%;
        &.multiple-block{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .office-thumb {
                width: calc(50% - .75em);
                text-align: left;
                &:nth-of-type(2n) {
                    margin-right: 0;
                }
                h6 {
                    @include font-size(17);
                    padding: 0 0 .25em;
                    margin: 0 0 .25em;
                    border-bottom: 1px solid $color9;
                }
                p {
                    line-height: 1.2;
                    margin: 0 0 1em;
                    padding: 0;
                }
            }
        }
    }
}


.footer-bottom{
    width: 100%;
    border-top: 1px solid $color11;
    padding: 18px 0;
    @media #{$mobile-landscape} {
        padding: 14px 0;
    }
    .copyright{
        font-size:  $size-base;
        color: $color10;          
        @media #{$tablet-portrait} {
           font-size: 14px;
        }
    }
}