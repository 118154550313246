/**********************************************
   Header 
***********************************************/
.js-off-canvas-overlay {
    background: rgba(0, 0, 0, .5);

    &.is-visible {
        @media #{$desktop-only} {
            display: none !important;
        }
    }
}

.off-canvas.position-left {
    background: $lighter;
    width: 300px;
    display: none;
    @include transition(all 0.5s ease-in-out);
    @include transform-translatex(-300px);

    @media #{$mobile-landscape} {
        display: block;
    }

    &.off-canvas.is-open {
        @include translate(0, 0);

        @media #{$desktop-only} {
            @include transform-translatex(-300px);
        }
    }
}

.off-canvas.is-transition-overlap.is-open {
    @media #{$desktop-only} {
        box-shadow: none;
    }
}

.left-off-canvas{
    padding: 25px 15px;
    .menu-logo{
        text-align: center;
        img{
            width: 170px;            
        }
    }
}

.menu-icon {
    display: none;
    position: absolute;
    right: 20px;
    top: 37px;
    width: 32px;
    height: 30px;
    outline: none;
    z-index: 9;

    &:focus {
        outline: none;
    }

    @media #{$mobile-landscape} {
        display: block;
    }

    &:after {
        width: 32px;
        height: 4px;
        background: $color1;
        box-shadow: 0 10px 0 $darker, 0 20px 0 $darker;
    }

    &:hover:after {
        height: 4px;
        background: $color1;
        box-shadow: 0 10px 0 $darker, 0 20px 0 $darker;
    }
}



.header-top {
    width: 100%;
    background: $color3;
    display: block;
    @media #{$mobile-landscape} {
        display: none;
    }
    .header-top-inner {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        padding: 10px 0 13px;
        @media #{$tablet-portrait} {
           padding: 10px 0 10px;
        }
        span {
            font-size: $size-base;
            color: $color4;
            font-family: $sub-font1;
            
            @media #{$tablet-portrait} {
               font-size: 13px;
            }
        }
    }
}

ul {
    &.contact-link {
        @include flexbox();
//        @media #{$tablet-portrait} {
//           @include flex-direction(column); 
//        }
        @media #{$mobile-landscape} {
            display: block;
            text-align: center;
        }
        li {
            padding-left: 58px;
            @media #{$tablet-portrait} {
                padding-left: 26px;
            }
            @media #{$mobile-landscape} {
                padding: 0;
            }
            &:first-child {
                padding: 0;
            }

            a {
                font-size: $size-base;
                color: $color4;
                font-family: $sub-font1;
                
                @media #{$tablet-portrait} {
                   font-size: 13px;
                }

                i {
                    margin-right: 7px;

                    &.phone {
                        background: url('../images/phone.svg') no-repeat;
                        width: 13px;
                        height: 17px;
                        display: inline-block;
                        position: relative;
                        top: 4px;
                        @media #{$tablet-portrait} {
                           margin-right: 2px;
                        }
                    }

                    &.mail {
                        background: url('../images/mail.svg') no-repeat;
                        width: 18px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    color: $color2;

                    i {
                        &.phone {
                            background: url('../images/phone-hover.svg') no-repeat;
                        }

                        &.mail {
                            background: url('../images/mail-hover.svg') no-repeat;
                        }
                    }
                }

            }
        }
    }
}

.header-bottom {
    width: 100%;
    background: $color5;

    .header-bottom-inner {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        padding: 33px 0;  
         
        @media #{$tablet-landscape} {
            padding: 26px 0;
        }
        @media #{$tablet-portrait} {
            padding: 25px 0;
        }
        @media #{$mobile-landscape} {
            padding: 15px 0;
        }   
    }
}

.header-links {
    padding: 19px 10px 0 0;
    
    @media #{$tablet-portrait} {
        width: 14%;
    }
    @media #{$mobile-landscape} {
        padding: 19px 60px 0 0;
    }
    ul {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        li {
            &.social-media {
                display: inline-block;
                vertical-align: top;
                padding: 0 20px;
                 
                @media #{$tablet-portrait} {
                   padding: 0 16px;
                }
                @media #{$mobile-landscape} {
                    padding: 0 12px;
                }
                a {
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            &.language-switcher {
                float: right;
                width: auto;
                position: relative;
                padding: 3px 0 3px 22px;
                @include align-self(center);
                @media #{$tablet-portrait} {
                   padding: 3px 0 3px 13px;
                }
                @media #{$mobile-landscape} {
                   padding: 3px 0 3px 12px;
                }
                &:before {
                    position: absolute;
                    content: "";
                    top: 2px;
                    left: 0;
                    height: 27px;
                    width: 1px;
                    background: $color6;
                }


                a.current-lang {
                    float: left;
                    width: 100%;
                    position: relative;
                    margin-right: 6px;

                    &:after {
                        width: 10px;
                        height: 6px;
                        content: "";
                        position: absolute;
                        right: -10px;
                        top: 10px;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJub3VuXzE3Mjg0NCI+CjxwYXRoIGlkPSJQYXRoIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuOTczOTkgMC4yNjAyNDFDOS45MTQyOCAwLjA4Njc0NyA5Ljc4MjkxIDAgOS42Mjc2NiAwSDAuMzcyMzRDMC4yMTcwOSAwIDAuMDg1NzI0IDAuMDg2NzQ3IDAuMDI2MDEyMyAwLjI2MDI0MUMtMC4wMzM2OTk1IDAuNDMzNzM1IDAuMDE0MDY5OSAwLjYwNzIyOSAwLjEyMTU1MSAwLjczNzM0OUw0Ljc1NTE4IDUuODg0MzRDNC44MjY4NCA1Ljk1NjYzIDQuOTEwNDMgNiA1LjAwNTk3IDZDNS4wODk1NyA2IDUuMTg1MTEgNS45NTY2MyA1LjI1Njc2IDUuODg0MzRMOS44Nzg0NSAwLjczNzM0OUM5Ljk4NTkzIDAuNjA3MjI5IDEwLjAzMzcgMC40MzM3MzUgOS45NzM5OSAwLjI2MDI0MVoiIGZpbGw9IiM2Rjc2OEMiLz4KPC9nPgo8L3N2Zz4K) !important;
                        background-size: 10px 6px;
                        background-repeat: no-repeat;

                    }

                    img {
                        width: 30px;
                        height: 20px;
                        opacity: 1 !important;

                    }
                }

                ul {
                    float: left;
                    width: 100%;
                    display: none;
                    position: absolute;
                    background: $lighter;
                    left: 8px;
                    top: 100%;
                    z-index: 1000;
                    -webkit-box-shadow: 0px 7px 7px 1px rgba(103, 103, 103, 0.1);
                    -moz-box-shadow: 0px 7px 7px 1px rgba(103, 103, 103, 0.1);
                    box-shadow: 0px 7px 7px 1px rgba(103, 103, 103, 0.1);
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                     
                    @media #{$tablet-portrait} {
                       left: 4px;
                    }
                    @media #{$mobile-landscape} {
                       left: 5px;
                    }

                    li {
                        float: left;
                        width: 100%;
                        text-align: center;

                        a {
                            float: left;
                            width: 100%;
                            padding: 4px 9px 0;

                            img {
                                width: 30px;
                                height: 20px;
                            }
                        }
                    }
                }

            }
        }
    }
}
.logo{
    
    @media #{$tablet-portrait} {
        width: 21%;
    }
    img{
        
       @media #{$tablet-landscape} {
            width: 190px;
        } 
       @media #{$tablet-portrait} {
            width: 170px;
        } 
       @media #{$mobile-landscape} {
            width: 135px;
        }   
    }
}

.main-menu {
    float: left;
    width: 48%;
    
    @media #{$tablet-landscape} {
       width: 56%;
    }
     @media #{$tablet-portrait} {
        width: 56%;
    }
    &.desktop-show{
        @media #{$mobile-landscape} {
            display: none;
        }  
    }
    &.mobile-show{
        @media #{$mobile-landscape} {
            width: 100%;
        }  
    }
    ul {
        float: left;
        width: 100%;
        padding-top: 13px;
        @media #{$mobile-landscape} {
            padding: 13px 0;
        }
        li {
            padding: 0px 18px;
            float: left;
            position: relative;
            margin: 0;
            @media #{$tablet-portrait} {
                padding: 0px 24px 0 0;
            }
            @media #{$mobile-landscape} {
                padding: 6px 0;
                width: 100%;
                text-align: center;
            }
            a {
                color: $color1;
                font-size: $size-base;
                text-transform: uppercase;
                font-family: $sub-font1;
                float: left;
                position: relative;
                
                &:hover,
                &:active,
                &:focus {
                    color: $color2;
                }
                @media #{$tablet-landscape} {
                    font-size: 14px;
                }
                @media #{$tablet-portrait} {
                    font-size: 13px;
                }
                @media #{$mobile-landscape} {
                    float: none;
                }
            }

            &.active {
                 & > a {
                    color: $color2;
                }
            }
            &.item-with-ul {
                position: relative;
                cursor: pointer;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 6px;
                    right: 2px;
                    background: transparent url('../images/svg/arrow_down_menu.svg') center center no-repeat;
                    @include mediaQuery(600,1024) {
                        top: 3px;
                        right: 6px;
                    }
                }
                .dropdown {
                    display: none;
                }
                @include mediaQuery(600,up) {
                    &:hover {                            
                        .dropdown {
                            background-color: darken($color5,5%);
                            position: absolute;
                            padding: 0 .25em;
                            z-index: 2;
                            top: 35px;
                            left: 50%;
                            margin-right: -50%;
                            transform: translateX(-50%);
                            display: block;
                            li {
                                margin: .25em 0;
                                a {
                                    @include font-size(13);
                                }
                            }
                            &:before {
                                position: absolute;
                                content: ' ';
                                display: block;
                                border-left: 8px solid transparent; 
                                border-right: 8px solid transparent; 
                                border-bottom: 10px solid darken($color5,5%); 
                                top: -10px;
                                left: 50%;
                                margin-right: -50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
                @include mediaQuery(599) {
                    &:after {
                        top: 12px;
                    }
                    .dropdown {
                        position: static;
                        background-color: darken($color5,5%);
                        li {
                            a {
                                @include font-size(14);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mediaQuery(599) {
    .mobile-show {
        ul {
            .dropdown {
                display: none;
                position: static;
            }
        }
    }
}