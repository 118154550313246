// Fonts
$title-font: $main-font;
$text-font:  $sub-font;
$pag-font:   $sub-font2;

// Colors
$title-font-color:   $title-color;
$text-font-color:    $text-color;
$list-title-color:  #66666D;
$date-text-color:   #505050;
$list-border-color: #D8D8D8;
$pag-border-color:   $color3;
$pag-text-color:    #2F3034;

.noticias-section {
    background-color: #fff;
    .inner-wrapper {
        .title-block    {
            margin: 90px 17px 60px;
            h1 {
                text-align: left;
                text-transform: none;
                @include font-size(42);
                font-family: $title-font;
            }
        }       
    }
    .noticias-list{
        margin: 0;

        .listado{
            margin: 0 0 30px 0;
            li{
                list-style: none;
                width: 90%;
                margin: 0 0 8px 0;
                padding: 0 0 10px 0;
                border-bottom: 1px solid $list-border-color;
                h3{
                    @include font-size(22);
                    color: $list-title-color;
                    line-height: 1.25em;
                    margin: 0 0 2px 0;
                    a {
                        color: inherit;
                        font-size: inherit;
                    }
                }
                em{
                    @include font-size(14);
                    color: $date-text-color;
                    margin-bottom: 16px;
                }
                img { margin-top: .5rem; }
            }
        }
        .pagination {
            text-align: left;
            margin: 2.5em 0 4.5em;
            li {
                padding: 0;
                margin-right: .35rem;  
                outline: none !important;         
                &:hover,&:focus {
                    background: transparent;
                    a {
                        color: #fff;
                        &:hover {
                            color: $link-color;
                        }
                    }
                }
                &.arrow {
                    border: 2px solid $pag-border-color;
                    border-radius: 50%;
                    img {
                        width: 12px;
                        height: auto;
                        display: inline-block;
                    }                            
                }
                &.unavailable {
                    &:hover {
                        background: transparent;
                    }
                    a {
                        color: $pag-text-color;
                        cursor: not-allowed;
                        &:hover, &:active, &:focus {
                            background: transparent;
                        }
                    }
                }
                &.current {
                    background: transparent;
                    a {
                        background: transparent;
                        color: $link-color;
                       
                    }
                    &:hover {
                        background: transparent;
                        a {
                            color: $link-color;
                        }
                    }
                }
                a {
                    padding: .35rem .8rem;
                    //border: 2px solid $pag-border-color;
                    font-family: $pag-font;
                    text-transform: uppercase;
                    color: $pag-text-color;
                    border-radius: 50%;
                    img {
                        vertical-align: middle;
                    }
                }
                @include breakpoint(medium only) {
                    &:not(.arrow) {
                        display: none;
                    }
                }
            }
        }
    }
    .noticias-item{
        padding-bottom: 90px;
        h2{
            clear: none;
            color: $title-font-color;
            @include font-size(32);            
            line-height: 1.25em;
            margin-bottom: .25em;
        }
        em.fecha{            
            color: $text-font-color;
            @include font-size(14);
        }
        .description{
            padding: 10px 0 0 0;
            h3,h4,h5,h6 {
                color: $title-font-color;
                font-family: $title-font;
                line-height: 1.25em;
            }
            h3 {
                @include font-size(28);
            }
            h4 {
                @include font-size(24);
            }
            h5 {
                @include font-size(20);
            }
            h6 {
                @include font-size(16);
            }
            strong{
                font-weight: bold;
            }
            u {
                text-decoration: underline;
            }
            ul,ol{
                list-style: disc;
                margin: 10px 0 30px 30px;
                li{
                    color: $text-color;  
                    font-size: 17px;      
                }
            }
            p, strong, b, span {
               @include font-size(17);
               line-height: 1.6;
            }
            p {
                margin-bottom: 1.5em;
                padding: 0;
                &:last-of-type {
                    margin-bottom: 3em;
                }
            }
            a {
                text-decoration: underline;
                color: $title-font-color;
            }
        }
        .first_image {
            margin: 1rem 0;
            width: 100%;
        }
        .images-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .image-block {
                width: calc((100% - 20px) / 2);
                height: 268px;
                margin: 0 20px 20px 0;
                overflow: hidden;
                &:nth-of-type(2n) {
                    margin-right: 0;
                }
                @include breakpoint(small only) {
                    width: 100%;
                    margin: 0 0 20px !important;
                    height: 220px;
                }
                @include breakpoint(medium only) {
                    width: 100%;
                    margin: 0 0 20px !important;
                }
                img {
                    width: 100%;
                    height: 268px;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }
}